
import { defineComponent, computed, ref, defineAsyncComponent } from 'vue';

export default defineComponent({
  components: {
    'basic-information': defineAsyncComponent(
      () => import('@/components/account/Account.vue'),
    ),
    'next-of-kin': defineAsyncComponent(
      () => import('@/components/account/NextOfKin.vue'),
    ),
  },
  setup: () => {
    const tab = ref<any>('basic-information');

    const successModal = ref<any>(null);

    const tabs = computed(() => {
      return [
        { title: 'Basic Information', value: 'basic-information' },
        { title: 'Next of Kin', value: 'next-of-kin' },
      ];
    });

    const success = () => successModal.value?.open();

    return { tabs, tab, successModal, success };
  },
});
