<template>
  <div class="sm:w-2/5 animated reveal">
    <div class="flex justify-between">
      <div class="mb-10 text-xl font-bold sm:text-2xl text-invest-primary">
        My account
      </div>
    </div>

    <tab :tabs="tabs" :tab="tab" v-model="tab" />

    <div class="mt-12 reveal animated">
      <component :is="tab" @success="success" />
    </div>
  </div>

  <alert-modal ref="successModal">
    <template #subtitle> Profile Updated Successfully </template>
    <template #body>
      <button
        class="mt-5 btn pt-1 btn-primary"
        type="button"
        @click="$refs.successModal.close()"
      >
        Okay
      </button>
    </template>
  </alert-modal>
</template>
<script lang="ts">
import { defineComponent, computed, ref, defineAsyncComponent } from 'vue';

export default defineComponent({
  components: {
    'basic-information': defineAsyncComponent(
      () => import('@/components/account/Account.vue'),
    ),
    'next-of-kin': defineAsyncComponent(
      () => import('@/components/account/NextOfKin.vue'),
    ),
  },
  setup: () => {
    const tab = ref<any>('basic-information');

    const successModal = ref<any>(null);

    const tabs = computed(() => {
      return [
        { title: 'Basic Information', value: 'basic-information' },
        { title: 'Next of Kin', value: 'next-of-kin' },
      ];
    });

    const success = () => successModal.value?.open();

    return { tabs, tab, successModal, success };
  },
});
</script>
